import Vue from 'vue'
import Vuex from 'vuex'
import {
  webUserGetCoinList,
  webUserGetAssetsList,
  webUserGetUserInfo,
  webUserQueryLevelList,
  webUserGetMainCoinTypeList,
  webGetQueryNoticeList,
  webGetQueryMediaList,
  getReturnWallet
} from "@/api/index" // 接口

import WS from '@/common/socket';
import { socketUrl } from '@/http/baseUrl'; 

Vue.use(Vuex);

let lang = localStorage.getItem('lang') || 'zh-CN';

export default new Vuex.Store({
  state: {
    loading: false,
    lang: lang,
    AssetsList: [],
    UserInfo: {},
    pageTitle: '主页',
    returnObj: {},
    socketObj: null,
    convertRate: {}
  },
  getters: {
    twoStepStatus(state) {
      return state.UserInfo.twoCheckStatus;
    },
    getAvailable(state) {
      let obj = {};
      if(state.AssetsList.length > 0) {
        state.AssetsList.forEach(item=> {
          obj[item.coinName] =  item.assetsAvailable;
        })
      }
      return obj
    }
  },
  mutations: {
    changeLoadingState(state, val) {
      state.loading = val;
    },
    SET_StoreMutationUserInfo(state, item) {
      state.UserInfo = item;
    },
    SET_StoreMutationAssetsList(state, assets) {
      state.AssetsList = assets;
    },
    SET_StorePageTitle(state, val) {
      state.pageTitle = val;
    },
    SET_StoreMutationReturnWallet(state, item) {
      state.returnObj = item;
    },
    SET_StoreSocketObj(state, item) {
      state.socketObj = item;
      state.socketObj.onmessage = (event)=> {
        let data = JSON.parse(event);
        // // console.log('data :>> ', data);
        if(data.topic === 'newPrice') {
          state.convertRate = data.data;
        }
      }
    },
  },
  actions: {
    async storeActionUserInfo(context) {  // 获取用户信息
      const { data } = await webUserGetUserInfo({});
      if (data.code === 20000) {
        console.log('UserInfo :>> ', data.data);
        context.commit('SET_StoreMutationUserInfo', data.data);
      }
    },
    async storeActionAssetsList(context) {  // 获取钱包列表
      const { data } = await webUserGetAssetsList({});
      if (data.code === 20000) {
        let records = data.data.records;
        // console.log('Assets :>> ', records);
        context.commit('SET_StoreMutationAssetsList', records)
      }
    },
    async storeActionReturnWallet(context) {  // 获取return wallet
      const { data } = await getReturnWallet({});
      if(data.code === 20000) {
        // console.log('getReturnWallet :>> ', data.data);
        // this.SET_StoreMutationReturnWallet(data.data);
        context.commit('SET_StoreMutationReturnWallet', data.data)
      }
    },
    storeSocketConnect(context) {  // 获取return wallet
      let bindId = localStorage.getItem('bindId');

      // console.log('bindId :>> ', bindId);
      let url = socketUrl;
      const WsObj = new WS(url, bindId);
      context.commit('SET_StoreSocketObj', WsObj)
    }
  },
  modules: {
    
  }
})
