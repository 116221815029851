import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

// 方式二
import zhLocaleI18n from "./cn";
import enLocaleI18n from "./en";
import jaLocaleI18n from "./jp";
import koLocaleI18n from "./kr";

Vue.use(VueI18n);

/**
 * 方式二
 * https://segmentfault.com/a/1190000020297356?utm_source=tag-newest TS中使用
 * {{ $t('m.message') }}
 */
let messages = {
  en: {
    ...enLocaleI18n,
  },
  zh: {
    ...zhLocaleI18n,
  },
  ja: {
    ...jaLocaleI18n,
  },
  kr: {
    ...koLocaleI18n,
  },
};

let getLocaleI18n = localStorage.getItem("lang") || "en-US";
let localeI18n = "en";

// // console.log(getLocaleI18n);

switch (getLocaleI18n) {
  case "en-US":
    localeI18n = "en";
    break;
  case "zh-CN":
    localeI18n = "zh";
    break;
  case "ja-JP":
    localeI18n = "ja";
    break;
  case "ko-KP":
    localeI18n = "kr";
    break;
}


/** this.$i18n.locale
 *  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'zh',
 */
const i18n = new VueI18n({
  locale: getLocaleI18n,
  // locale: localeI18n,
  messages,
  silentTranslationWarn: true 
});

export default i18n;
